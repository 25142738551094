<template>
    <div>
        <input hidden type="text" class="form-control" id="ProjectId" v-model="ProjectId">
        <div class="modal fade" id="ProjectStatusModal" tabindex="-1" aria-labelledby="ProjectStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="ProjectStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{ProjectCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                       
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import ProjectServices from '../Script/ProjectServices.js';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ProjectStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            ProjectId : '',
            ProjectCode : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(ProjectData){
            this.ModalTitle = 'Edit Status Project: ';
            this.ProjectId = ProjectData.project_id;
            this.ProjectCode = ProjectData.project_code;
            
            this.StatusData = await globalfunc.globalDropdown('ddl_status_project');
            this.Status = ProjectData.status;
            
            window.$('#ProjectStatusModal').modal('show');
        },
        async saveClick(){
            var data = await ProjectServices.getProjectDetailQuery(this.ProjectId);
            
            var forecastData = this.$globalfunc.objectToArrayConverter(data.project_item_forcast, 'Project-ForecastForceStatus');
            var afiliationData = this.$globalfunc.objectToArrayConverter(data.project_affiliate, 'Project-ProjectAfiliationGrid');
            var distributorData = this.$globalfunc.objectToArrayConverter(data.project_distributor, 'Project-ProjectDistributorGrid');
            var consultantData = this.$globalfunc.objectToArrayConverter(data.project_consultant, 'Project-ProjectConsultantGrid');
            var salesData = this.$globalfunc.objectToArrayConverter(data.project_sales, 'Project-ProjectSalesGrid');
            var otherData = this.$globalfunc.objectToArrayConverter(data.project_etc, 'Project-ProjectOther');
            var typeDetailData = this.$globalfunc.objectToArrayConverter(data.project_discount, 'Project-ProjectTypeDetailForceStatus');

            const projectData = {
                project_code: data.project_code,
                project_type: data.project_type, //project = 1, template = 0, retail = 2
                project_date: data.project_date,
                project_duration: data.project_duration,
                project_name: data.project_name,
                status: this.Status,
                pre_project_id: data.pre_project_id,
                forecast_type: data.forecast_type,
                storage_id: data.storage_id,
                price_category_id: data.price_category_id,
                areacode: data.areacode,
                sales_id: data.sales_id,
                city: data.city,
                attachment: null,
                notes: data.notes,
                project_detail: data.project_detail,
                project_affiliate: afiliationData,
                project_distributor: distributorData,
                project_consultant: consultantData,
                project_sales: salesData,
                project_etc: otherData,
                project_discount: typeDetailData,
                project_item_forcast: forecastData
            };
            
            const variables = {
                id: this.ProjectId,
                data: projectData
            };
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    ProjectServices.editQuery(variables).then(res => {
                        window.$('#ProjectStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>